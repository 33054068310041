import { createRouter, createWebHashHistory } from "vue-router";
import LoginPage from "../views/LoginPage.vue";

import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "loginpage",
    component: LoginPage,
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/SignUp.vue"),
  },
  {
    path: "/main",
    name: "homepage",
    component: () => import("../views/HomeView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/HelpFaqs.vue"),
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/forgotpass",
    name: "forgotpass",
    component: () => import("../views/ForgotPage.vue"),
  },
  {
    path: "/changepass",
    name: "changepass",
    component: () => import("../views/ChangepassPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/timetable",
    name: "timetable",
    component: () => import("../views/TimetablePage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/weeklytest",
    name: "weeklytest",
    component: () => import("../views/WeeklytestPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/terminaltest",
    name: "terminaltest",
    component: () => import("../views/TerminalPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/datesheet",
    name: "datesheet",
    component: () => import("../views/DatesheetPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/messages",
    name: "messages",
    component: () => import("../views/MessagesPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/homework",
    name: "homework",
    component: () => import("../views/HomeworkPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () => import("../views/GalleryPage.vue"),
  },
  {
    path: "/syllabus",
    name: "syllabus",
    component: () => import("../views/SyllabusPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pagenotfound",
    name: "pagenotfound",
    component: () => import("../views/UnderConstruction.vue"),
  },
  {
    path: "/duepay/:glc/:uname",
    name: "duepayment",
    component: () => import("../views/DuePayment.vue"),
  },
  {
    path: "/delaccount",
    name: "delaccount",
    component: () => import("../views/DeleteAccount.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/payhist/:glc",
    name: "paymenthistory",
    component: () => import("../views/PaymentHistory.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// Global navigation guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.isAuthenticated; // Replace with your authentication logic

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the route requires authentication
    if (!isAuthenticated) {
      // User is not authenticated, redirect to login or a restricted page
      next("/");
    } else {
      // User is authenticated, proceed to the requested page
      next();
    }
  } else {
    // Route does not require authentication, allow access
    next();
  }
});

export default router;
